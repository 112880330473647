<template>
	<el-table :data="tableData" style="width: 100%">
		<el-table-column label="赛季" width="140">
			<template slot-scope="scope">
				<i class="el-icon-time"></i>
				<span style="margin-left: 10px">{{ scope.row.resultyear }}</span>
			</template>
		</el-table-column>
		<el-table-column label="赛事名称" width="180">
			<template slot-scope="scope">
				<span style="margin-left: 10px">{{ scope.row.gamename }}</span>
			</template>
		</el-table-column>
		<el-table-column label="审核备注" width="300">
			<template slot-scope="scope">
				<span style="margin-left: 10px">{{ scope.row.resultcomment }}</span>
			</template>
		</el-table-column>
		<el-table-column label="状态" width="120">
			<template slot-scope="scope">
				<el-popover trigger="hover" placement="top">
					<p>状态: {{ (Number(scope.row.resultstatus)==2?'已通过':((scope.row.resultstatus)==1?'未通过':'未审核')) }}</p>
					<p>附注: {{ scope.row.resultcomment }}</p>
					<div slot="reference" class="name-wrapper">
						<el-tag :type="(scope.row.resultstatus==2?'success':(scope.row.resultstatus==1?'warning':'primary'))" size="medium">
							{{ (scope.row.resultstatus==2?'已通过':(scope.row.resultstatus==1?'未通过':'未审核'))  }}
						</el-tag>
					</div>
				</el-popover>
			</template>
		</el-table-column>
		<el-table-column label="操作">
			<template slot-scope="scope">
				<el-button size="mini" @click="handleView(scope.$index, scope.row)"
					>详情</el-button
				>
			</template>
		</el-table-column>
	</el-table>
</template>

<style>
.el-message-box {
	width: 500px;
}
</style>

<script>
import axios from "axios";
//import qs from "qs";
export default {
	name: "resultList",
	data() {
		return {
			userid: -1,
			tableData: [],
		};
	},
	beforeMount() {
		axios({
			url: "/api/hello-user",
			method: "GET",
			headers: {
				Authorization: localStorage.getItem("token"),
			},
		})
			.then((res) => {
				// console.log("userid is");
				this.userid = res.data.userid;
				axios({
					url: "/api/admin/result/get",
					method: "GET",
					headers: {
						Authorization: localStorage.getItem("token"),
					},
				})
					.then((res) => {
						// console.log(res.data);
						this.tableData = res.data;
					})
					.catch((err) => {
						this.messageBox(
							"获取结果列表失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
							"error"
						);
						console.log(err.response);
					});
			})
			.catch((err) => {
				this.messageBox(
					"获取用户信息失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
				console.log(err.response);
			});
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
		handleView(index, row) {
			// console.log(index, row);
			var resultText = "";
			var item = null;
			resultText = "<h4>总冠军</h4>";
			resultText += row.resultinfo.champion;
			resultText += "<br>";
			resultText = resultText + "<h4>甲组成绩</h4>";
			for (var key1 in row.resultinfo.result['1']) {
				// console.log(key1, row.resultinfo.result['1'][key1]);
				item = row.resultinfo.result['1'][key1];
				resultText =  resultText + "第" + item.ranking + "： " + item.deptname + "<br>";
			}
			resultText = resultText + "<h4>乙组成绩</h4>";
			for (var key2 in row.resultinfo.result['2']) {
				// console.log(key2, row.resultinfo.result['2'][key2]);
				item = row.resultinfo.result['2'][key2];
				resultText =  resultText + "第" + item.ranking + "： " + item.deptname + "<br>";
			}
			this.$alert(resultText, row.gamename + '比赛成绩', {
				confirmButtonText: '收起',
				center: true,
				dangerouslyUseHTMLString: true,
			});
		},
	},
};
</script>